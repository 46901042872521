$mobile-width: 600px;
$mobile-small-width: 420px;
$tablet-large: 1200px;
$tablet: 800px;

@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
@mixin mobile-small {
  @media screen and (max-width: $mobile-small-width) {
    @content;
  }
}
@mixin tablet-large {
  @media screen and (max-width: $tablet-large) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}
.page-result {
  padding-bottom: 0.1px;
  #imageResult {
    // width : 300px;
    // height: 212px;
    max-width: 450px;
    max-height: 450px;
    @include mobile {
      max-width: 300px;
    }
  }
  #ai-skin-care {
    .content {
      margin: 20px 0;
    }
    h6 {
      text-align: center;
      line-height: 21px;
      font-size: 15px;
      margin: 0;
    }
  }
  .title-page {
    font-family: "SFU Futura", sans-serif;
    text-align: center;
    font-size: 16px;
    padding: 40px 0 0 0;
    @include mobile {
      font-size: 14px;
    }
  }
  .img-section {
    margin: 0 0 30px 0;
  }
  .title-section {
    font-size: 16px;
    font-weight: bold;
    @include mobile {
      font-size: 14px;
    }
  }
  .desc-section {
    font-size: 15px;
    @include mobile {
      font-size: 14px;
    }
  }
  .notice {
    width: 100%;
    background-color: #f1f4f1;
    color: #000;
    padding: 20px;
    text-align: center;
    p {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 14px;
      margin: 0;
      @include mobile {
        font-size: 12px;
      }
    }
  }

  .tab-info {
    width: 100%;
    display: flex;
    padding: 10px 0;
    .left {
      width: 30%;
      img {
        height: 140px;
      }
    }
    .right {
      width: 70%;
      position: relative;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding: 10px 15px;
      background-color: #e9f3ff;
      height: 100%;
      font-size: var(--main-font-size);
      line-height: 18px;
      height: 140px;
      .box-content {
        .title {
          font-size: 15px;

          font-weight: bold;
          margin: 0 0 8px 0;
          @include mobile {
            font-size: 14px;
          }
        }
        span {
          display: block;
          font-size: 13px;
          margin: 0 0 8px 0;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
  }
  .list_conclusion_section {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    .result-item {
      height: max-content;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      .avatar-image {
        padding: 0 10px 0 0;
        width: 30%;
        @include mobile {
          width: 40%;
        }
        img {
          width: 100%;
          height: 100%;
          max-height: 300px;

          @include mobile {
            height: auto;
            max-height: 200px;
          }
        }
      }
      .content-description {
        width: 70%;
        padding: 0px 0px 0 10px;
        text-align: justify;
        .title-bold {
          font-weight: bold;
          font-size: 16px;
          @include mobile {
            font-size: 14px;
          }
        }
        @include mobile {
          width: 60%;
        }
        span {
          font-size: 15px;
          font-weight: bold;
          @include mobile {
            font-size: 12px;
          }
        }
        ul {
          list-style-type: disc;
          margin-left: 0px;
          padding-left: 10px;
          padding-right: 10px;
          li {
            padding-left: 0;
            font-size: 15px;
            @include mobile {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .title-box {
    margin-top: 30px;
    width: 100%;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    .box-color {
      width: 52px;
      height: 104px;
      background: rgba(151, 151, 151, 0.3);
      @include mobile {
        height: 80px;
      }
    }
    .title-large {
      position: relative;
      left: -20px;
      font-size: 30px;
      line-height: 42px;
      @include mobile {
        font-size: 26px;
      }
    }
  }

  .description-tuvan {
    height: max-content;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .image-demo {
      width: 40%;
      img {
        width: 100%;
        height: 240px;
        @include mobile {
          height: 200px;
        }
      }
    }
    .content-tuvan {
      width: 60%;
      h6 {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        padding: 0 0 20px 0;
        @include mobile {
          padding: 0;
          font-size: 16px;
        }
      }
      span {
        font-size: 16px;
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .right {
      padding: 0 0 0 15px;
    }
    .left {
      padding: 0 15px 0 0;
    }
  }
  .btn-suggest {
    margin: 20px 0;
    padding: 20px 0;
    display: flex;
    background-color: #e9f3ff;
    width: 100%;
    justify-content: center;
    img {
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: var(--main-font-size);
      padding: 10px;
      min-width: 234px;
      color: #fff;
      background-color: var(--color-main);
      text-decoration: none;
    }
  }
}
