
.modal-loading{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    display: block;
}
.box-loading-img{
    position: absolute;
    transform: translate(-50%,-50%);
    top : 50%;
    left: 50%;
    img{
        width: 50px;
        height: 50px;
    }
}