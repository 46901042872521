$mobile-width: 600px;
$tablet: 800px;
$mobile-small-width: 420px;
$tablet-large: 1200px;
@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
@mixin mobile-small {
  @media screen and (max-width: $mobile-small-width) {
    @content;
  }
}
@mixin tablet-large {
  @media screen and (max-width: $tablet-large) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}
.banner-layout {
  position: relative;

  // background-image: url('https://community.pega.com/sites/default/files/styles/1920/public/media/images/2021-11/DocsDynamic.png?itok=4tmwrp2L');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include mobile {
    min-height: 100%;
    height: 100%;
  }
  .circle {
    position: absolute;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  .circle:nth-child(1) {
    background-color: rgba(255, 145, 76, 0.3);
    left: 91%;
    bottom: 50%;
    width: 50px;
    height: 50px;
    transform: rotate(35deg);
  }
  .circle:nth-child(2) {
    background-color: rgb(205 57 33 / 64%);
    left: 10%;
    bottom: 30%;
    width: 20px;
    height: 20px;
    transform: rotate(60deg);
    border-radius: 50%;
  }
  .circle:nth-child(3) {
    background-color: rgb(40 118 31 / 30%);
    left: 96%;
    bottom: 24%;
    width: 20px;
    height: 20px;
    transform: rotate(60deg);
    border-radius: 50%;
  }
  .circle:nth-child(4) {
    background-color: rgba(255, 145, 76, 0.3);
    left: 23%;
    bottom: 85%;
    width: 50px;
    height: 50px;
    transform: rotate(60deg);
    border-radius: 50%;
  }

  .circle:nth-child(5) {
    background-color: rgb(235 208 185 / 35%);
    left: 26%;
    bottom: 85%;
    width: 40px;
    height: 40px;
    transform: rotate(60deg);
    border-radius: 50%;
  }
  .circle:nth-child(6) {
    background-color: rgb(235 208 185 / 35%);
    left: 3%;
    bottom: 85%;
    width: 40px;
    height: 40px;
    transform: rotate(60deg);
  }
  .circle:nth-child(7) {
    background-color: rgba(220, 183, 235, 0.35);
    left: 6%;
    bottom: 10%;
    width: 40px;
    height: 40px;
    transform: rotate(60deg);
  }
  .circle:nth-child(8) {
    background-color: rgb(235 183 205 / 46%);
    left: 18%;
    bottom: 70%;
    width: 30px;
    height: 30px;
    transform: rotate(60deg);
    border-radius: 50%;
  }
  .height {
    // min-height: 500px;
  }
  .flex {
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    @include tablet-large {
      display: block;
      padding-top: 0;
      width: 100%;
    }
    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }
    .banner-img {
      width: 100%;
      margin-top: 10px;
      height: 113px;
      overflow-y: hidden;
      .flickity-page-dots {
        bottom: 10px !important;
        display: none;
      }

      .flickity-button {
        display: none;
      }
      .banner-img-effect {
        height: 113px;
      }
      @include mobile {
        margin-top: 10px;
        width: 100%;
        height: 113px;
      }
      img {
        cursor: pointer;
        right: 0;
        width: 100%;
        height: 113px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        @include tablet-large {
          height: 113px;
          width: 100%;
          top: 0;
        }
        @include mobile {
          height: 113px;
          width: 100%;
          top: 0;
        }
      }
    }
  }
  .content {
    width: calc(35% - 15px);
    @include mobile {
      min-height: auto;
      width: 100%;
    }
    @include tablet-large {
      min-height: auto;
    }
    @include tablet {
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 30px;
    }
    @include mobile {
    }
    h4 {
      font-size: 40px;
      font-weight: 300;
      margin: 0;
      @include mobile {
        font-size: 32px;
      }
      @include mobile-small() {
        font-size: 28px;
      }
    }
    h3 {
      font-size: 42px;
      font-weight: bold;
      margin: 0;

      @include mobile {
        font-size: 34px;
      }
      @include mobile-small() {
        font-size: 30px;
      }
    }
    .flex-text {
      display: flex;
      gap: 10px;
      margin-top: 5px;
      @include tablet {
        justify-content: center;
      }
      h3 {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          background-color: var(--color-main);
          width: 105%;
          left: -4px;
          height: 105%;
          // opacity: .7;
          z-index: -1;
          transform: rotate(-2deg);
        }
      }
    }
    .intro {
      margin-top: 10px;
      p {
        padding: 0;
        margin: 0;
        @include mobile {
          font-size: 14px;
        }
      }
      span {
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .button {
      display: inline-block;
      padding: 0;
      margin-top: 25px;
      @include mobile {
        margin-top: 10px;
      }
      button {
        padding: 10px 20px;
        border: 1px solid var(--color-main);
        background-color: var(--color-main);
        color: #fff;
        transition: 0.2s ease;
        @include tablet {
          padding: 11px;
          font-size: 13px;
        }
        &:hover {
          transform: scale(1.05);
        }
        svg {
          margin-left: 2px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
.box-about {
  position: relative;
  height: 100%;
  margin-bottom: 0px;
  width: 80%;
  left: calc(50% - 40%);
  z-index: 1;
  // background-color: var(--white);
  // background: rgba(255, 145, 76, .8);
  background-color: var(--color-main);
  transform: translate(0, -80px);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
  padding: 30px 30px 30px 30px;
  @include tablet-large {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 20px 10px 20px 10px;
    left: 0;
  }
  @include mobile {
    transform: translate(0, 0px);
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 20px 10px 20px 10px;
    left: 0;
  }
  .title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 16px;
    color: var(--white);
    @include mobile {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  .title-after {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-color: var(--color-main);
      width: 105%;
      left: -4px;
      height: 105%;
      opacity: 0.7;
      z-index: -1;
      transform: rotate(-2deg);
    }
  }
  .text {
    color: var(--white);

    font-size: 18px;
    margin-bottom: 15px;
    @include mobile {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .list {
    display: grid;
    gap: 35px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    @include mobile {
      gap: 0;
      display: block;
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 16px 16px;
      background-color: var(--white);
      // border: 1px solid var(--color-blue-main);
      border-radius: 16px;
      transition: transform 0.2s ease;
      cursor: pointer;
      &:hover {
        transform: scale(1.05) !important;
      }
      @include mobile {
        margin-bottom: 10px;
        padding: 16px 8px;
      }
      .icon {
        margin-bottom: 20px;
        border-radius: 16px;
        background-color: var(--white);
        padding: 16px 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;
        @include mobile {
          padding: 10px 12px;
          margin-bottom: 10px;
        }
        svg {
          width: 36px;
          height: 36px;
          @include mobile {
            width: 26px;
            height: 26px;
          }
        }
      }
      p {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        // color: var(--white);
        @include mobile {
          margin: 0 0 10px 0;
        }
      }
      .description {
        text-align: center;
        font-size: 14px;
        // color: var(--white);
      }
    }
    .item--green {
      // background-color: #708258;
      .icon {
        svg {
          // fill: #708258;
          fill: var(--color-main);
        }
      }
    }
    .item--red {
      // background-color: #c96768;
      .icon {
        svg {
          // fill: #c96768;
          // color: #c96768;
          color: var(--color-main);
        }
      }
    }
    .item--blue {
      // background-color: #8dd2c9;
      .icon {
        svg {
          // fill: #8dd2c9;
          // color : #8dd2c9;
          fill: var(--color-main);
        }
      }
    }
  }
}
