



.box-render__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,0.4);
    z-index: 1;

}

.box-render__btn-analysis {
    width: 450px;
    border: none;
    display: block;
    padding: 8px;

    margin: 25px 0 60px;
    font-weight: 600;
    color: #fff;
    background-color: var(--color-main);
}



.wp-loading {
    height:auto !important;
    position: absolute;
    left: calc(50% - 0.5em) !important;
    top: calc(50% - 0.5em) !important;
}




@media (max-width: 800px) {

    

    
}


