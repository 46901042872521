.tip_content {
  background-color: #fff;
  border-radius: 20px;
  margin: 15% auto;
  padding: 15px;
  width: 320px;
  .title {
    display: inline-block;
    font-family: "SFU Futura", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: var(--main-font-size);
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;
    color: #1c213f;
  }
  .body {
    padding-left: 15px;
    padding-right: 10px;
    list-style: none;
    li {
      padding: 5px;
      font-family: SFU Futura;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
    }
  }
  .button-hide {
    width: 280px;
    margin: auto;
    z-index: 4000;
    cursor: pointer;
    button {
      width: 100%;
      -webkit-appearance: button;
      height: 45px;
      font-size: 18px;
      border-radius: 10px;
      line-height: 17px;
      color: #fff;
      font-family: SFU Futura;
      font-style: normal;
      font-weight: bold;
      padding: 10px 14px;
      border: none;
      background-color: var(--color-main);
    }
  }
}
