$mobile-width: 600px;
$mobile-small-width: 420px;
$tablet-large: 1200px;
$tablet: 800px;
$mobile-360: 360px;

@mixin mobile360 {
  @media screen and (max-width: $mobile-360) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
@mixin mobile-small {
  @media screen and (max-width: $mobile-small-width) {
    @content;
  }
}
@mixin tablet-large {
  @media screen and (max-width: $tablet-large) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}
.header-bg {
  margin-top: 20px;
  text-transform: uppercase;
  @include mobile-small {
    margin-top: 10px;
  }
}
.header {
  position: relative;
  text-align: center;
  color: var(--color-main);
  max-width: 86%;
  margin: auto;
  .header__title {
    margin-left: 10px;
    margin-bottom: 5px;
    display: flex;
    font-family: SFU Futura;
    font-size: 59px;
    line-height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      font-size: 33px;
      line-height: 30px;
    }
    @include mobile360 {
      font-size: 30px;
      line-height: 27px;
    }
    .title--text-1 {
      font-weight: bold;
      margin-right: 10px;
      text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
    .title--text-2 {
      text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
    .title--text-3 {
      font-family: SFU Futura;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 30px;
      @include mobile {
        font-size: 18px;
        line-height: 11px;
      }
      @include mobile360 {
        font-size: 17px;
        line-height: 10px;
      }
    }
  }
  .header__back {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    z-index: 1000000;
    cursor: pointer;
    .back__box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--color-main);
    }
    .back__icon {
      color: #fff;
      font-size: 25px;
    }
  }

  .header__sologan {
    display: flex;
    font-family: SFU Futura;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: var(--color-main);
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 10px;
      @include mobile {
        display: none;
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
    span {
      @include mobile {
        font-size: 8px;
      }
      @include mobile360 {
        font-size: 9px;
      }
    }
  }
}
