.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-main-2);
}

.ant-tabs-ink-bar {
    background: var(--color-main-2);
}
.ant-tabs-tab {
    font-size:18px;
}

.ant-tabs-tab:hover {
    color: var(--color-main);
    opacity: 0.8;
}

.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 50px;
}