$mobile-width: 600px;
$mobile-small-width: 420px;
$tablet-large: 1200px;
$tablet: 800px;

@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
@mixin mobile-small {
  @media screen and (max-width: $mobile-small-width) {
    @content;
  }
}
@mixin tablet-large {
  @media screen and (max-width: $tablet-large) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}
.not-found-product {
  font-size: 16px;
  font-weight: bold;
}

.success-gift {
  text-align: center;
  padding: 40px 0 0px 0px;
  h2 {
    font-weight: bold;
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
}
.page-product-suggest {
  .box-list {
    margin: 40px 0;
    .title {
      text-align: center;
      font-weight: bold;
      padding: 22px 0;
      color: #1991b4;
      font-size: 16px;
    }
    .list {
      // display: grid;
      // width: 100%;
      // grid-template-columns: 1fr 1fr 1fr;
      // grid-gap: 20px;
      // gap: 20px;
      // @include mobile {
      //     grid-template-columns: 1fr 1fr;

      // }

      .flickity-page-dots {
        display: none;
      }
      .item {
        margin-right: 20px;
        width: 33.33%;
        a {
          color: var(--bg-black-main);
          text-decoration: none;
          line-height: 1;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          max-width: 100%;
        }
        @include mobile {
          width: 50%;
        }
        .box-img {
          width: 164px;
          height: 164px;
          width: 100%;

          background-position: center !important;
          background-size: cover !important;
          background-repeat: no-repeat !important;
        }
        .name {
          margin: 10px 0;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.box-title-recommend {
  text-align: center;
  background-color: var(--color-main);
  color: #fff;
  padding: 20px;
  text-transform: uppercase;
  margin-top: 25px;
  .title-recommend__center {
    p {
      margin: 0;
    }
  }
  .title-recommend__strong {
    margin-top: 2px;
    height: 30px;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
  }
}

.box-advice {
  background-color: #e9f3ff;
  padding: 25px 10px;
  text-align: center;
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 3px;
  p {
    margin: 0;
  }
  @include mobile {
    font-size: 13px;
  }
}

.wp-form-giveaway {
  margin: 30px 0;
  padding: 0 0 20px 0;
  .giveaway__btn {
    border: none;
    outline: none;
    margin: 0 auto;
    padding: 10px;
    font-weight: bold;
    font-size: var(--main-font-size);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      line-height: 21px;
    }
    svg {
      margin-right: 3px;
      width: 18px;
      height: 18px;
    }
  }
  .giveaway__btn--size-l {
    text-transform: uppercase;
    background: #a82f31;
  }
  .giveaway__btn--size-s {
    background: var(--color-main);
    margin-top: 10px;
  }
  .form-group {
    margin-top: 30px;
    .form-label {
      font-size: 15px;
      line-height: 21px;
      color: #000;
      span {
        color: red;
      }
    }
    .form-control {
      background-color: #f1f4f1;
    }
  }
}
