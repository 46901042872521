.ai-skin__result-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ai-skin__result-image {
  position: relative;
}
.ai-skin__result-checkContainer{
    width: 300px;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
}
.ai-skin__result-checkbox{
    display: flex;
    align-items: center;
}
.ai-skin__result-checkbox-input{
  display: grid;
  grid-template-areas: "checkbox";
  align-items: center;
}
.ai-skin__result-plot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ai-skin__result-plot__box{
  border: 1px solid #000;
  position: absolute;
}
.ai-skin__result-checkbox-input input{
  position: absolute;
  opacity: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.ai-skin__result-checkmark {
  position: relative;
  display: inline-grid;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 1px;
  background: #fff;
}
.ai-skin__result-checkbox-label{
  margin-left: 5px;
}
.ai-skin__result-checkbox-input input[id=myCheckSpot]:checked+.ai-skin__result-checkmark>span{
  background: #fea500;
  border-radius: 50%;
}
.ai-skin__result-checkbox-input input[id=myCheckAcne]:checked+.ai-skin__result-checkmark>span{
  background: #ff0;
  border-radius: 50%;
}
.ai-skin__result-checkbox-input
  input[id="myCheckPimple"]:checked
  + .ai-skin__result-checkmark
  > span {
  border-radius: 50%;
  background: #00a853;
}
.ai-skin__result-checkbox-input
  input[id="myCheckBlackhead"]:checked
  + .ai-skin__result-checkmark
  > span {
  border-radius: 50%;
  background: #ff50af;
}
.ai-skin__result-checkbox-input
  input[id="myCheckMole"]:checked
  + .ai-skin__result-checkmark
  > span {
  border-radius: 50%;
  background: #fe0000;
}