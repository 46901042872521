
:root {
    --bg-black-main : #212121;
    --black-main-web : #313539;
    --white : #fff;
    --color-main : #311d4f;
    --color-main-2 : #fe4f81;
    --color-blue-main: #2b2847;
    --height-share: 24px;
    --mt-share: 20px;
    --main-font-size: 16px;
}