
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'antd/dist/antd.css';
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 14px;
  box-sizing: inherit;
  font-family: 'SFU Futura', sans-serif !important;

}
body {
  min-height: 100vh; 
  scroll-behavior: smooth; 
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  font-family: 'SFU Futura', sans-serif !important;

}
/* body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  width: 10px;
  background: var(--color-main);
  border-radius: 10px;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
  text-decoration: none;
}
svg {
  cursor: pointer;
}
button{
  cursor: pointer;
}
.flex{
  display: flex;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-a-center{
  display: flex;
  align-items: center;
}
.flex-j-center{
  display: flex;
  justify-content: center;
}
.text-center{
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--color-main);
}