
.container-layout{
    max-width: 600px;
    margin: 0 auto;
}
.button{
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.r6{
    border-radius: 6px;
}
.r8{
    border-radius: 8px;
}
.r20{
    border-radius: 20px;
}

.wp-box {
    margin-top: 30px;
}
.box-head {
    text-align: center;
}

.box-body {
    margin-top: 50px;
}

.box-title{
    text-transform: uppercase;
    font-size: 28px;
    font-weight:700;    
}
.homepage-space__elm-has-page-dots{
    padding-bottom: 20px;
}
.homepage__title-section{
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
}
.ovf-hidden{
    overflow: hidden;
}
.show{
    display: block !important;
}
.btn-main-hover{
    transition: .1s;
}
.btn-main-hover:hover{
    /* background-color: var(--color-main); */
    background: var(--color-main);
    color: var(--white) !important;
    border: 1px solid var(--color-main) !important;
    transform: scale(1.05) !important;
}
.space-top-bot-page{
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}
.not-found-product.pd{
    padding: 40px 0;
    font-size: 20px;
  }
@media screen and (max-width: 1200px){
    
    .container-layout{
        max-width: 100%;
        padding: 0 10px;
    }    
    .homepage__title-section{
        font-size: 20px;
    }
    .home-product__wp-heading {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 800px) {
    .wp-box {
        margin-top: 20px;
    }
    .box-title {
        font-size:22px;
    }
    .box-body {
        margin-top: 20px;
    }
}
@media screen and (max-width: 600px){
    .button{
        font-size: 12px !important;
    }
    .space-top-bot-page{
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }
    .center-mobi{
        display: flex;
        justify-content: center;
    }
}

