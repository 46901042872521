.wp-render {
    width: 450px;
    margin: 0 auto;
}
.box-render__photo {
   position: relative;
   width: 450px;
    height: 450px;
}
.box-render__photo-webcam {
    position: absolute;
    left: 0;
    top: 0;
    bottom:0;
    right: 0;
}
.box-render__photo img {
    width: 450px;
    height: 450px;
    border-radius: 5px;
    object-fit: cover;
}

.box-render__description {
    text-align: center;
    margin: 10px 0 20px;
    font-size: 16px;
    font-weight: 600;
}

.box-render__handle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: 60px 0 30px 0;
}

.box-render__handle-item p{
    margin-top: 10px;
}

.box-render__btn {
    padding: 15px;
    border-radius: 50%;
}
.box-render__handle-item{
    position: relative;
}
.box-render__btn--camera {
    background-color: var(--color-main);
    border: none;
}
.btn-test{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* display: none; */
    opacity: 0

}
.btn-test input{
  
}
.box-render__btn--upload {
    cursor: pointer;
    width: 56px;
    height: 56px;
    border: 1px solid var(--color-main);
    background-color: #fff;
    
    position: relative;
    overflow: hidden;
}
.box-render__btn--upload svg{
    fill : var(--color-main);
    color:  var(--color-main);
}

input[type="file"] {
    /* position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    outline: none;
    background: white;
    cursor: inherit;
    cursor: pointer; */
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
input[id="imageCamera"] {display: none;}

.box-render__description-text {
    padding: 10px;
}

.box-render__description-text-item {
    font-family: SFU Futura;
    font-style: normal;
    font-weight: normal;
    font-size: var(--main-font-size);
    line-height: 17px;
    text-align: justify;
    color: #000;
    margin: 0;
} 

@media (max-width: 800px) {
    .box-render__photo img {
        width: 450px;
        height: 450px;
    }

    .box-render__photo-webcam video {
        width: 100%;
        height:auto;
    }

    .categories__item-detail {
        width: 100%;
    }
}
@media (max-width: 500px) {
    .box-render__photo img {
        width: 100% !important;
        height: auto !important;
    }

}

@media (max-width: 450px) {
    .box-render__photo{
        width: 100% !important;
        height: auto !important;
    }

}