@import "./flickity.css";
@import "./general.css";
@import "./editcssantd.css";
@font-face {
  font-family: "SFU Futura";
  src: url("./fonts/SFUFuturaRegular.TTF");
}
@font-face {
  font-family: "SFU Futura";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/SFUFuturaRegular.TTF");
}
@font-face {
  font-family: "SFU Futura";
  src: url("./fonts/SFUFuturaBold.TTF");
  font-weight: bold;
}
@font-face {
  font-family: "SFU Futura";
  src: url("./fonts/SFUFuturaObliqueTTF.TTF");
  font-style: oblique;
}
@font-face {
  font-family: "SFU Futura";
  src: url("./fonts/SFUFuturaObliqueTTF.TTF");
  font-style: italic;
}
@font-face {
  font-family: "SFU Futura";
  src: url("./fonts/SFUFuturaBoldOblique.TTF");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "SFU Futura";
  src: url("./fonts/SFUFuturaLight.TTF");
  font-style: lighter;
}
html {
  font-family: "SFU Futura" !important;
}
.container-button-back {
  cursor: pointer;
  margin-top: 20px;
  /* font-size: 14px; */
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--black-main-web);
}
.container-button-back svg {
  fill: var(--black-main-web);
  margin-right: 6px;
  width: 14px;
  height: 14px;
}
.container-button-back--a {
  text-decoration: none;
}
.title-category {
  font-size: 14px;
  padding-bottom: 20px;
  font-weight: 600;
  /* text-align: center; */
  margin: 20px 0 0 0;
  color: var(--black-main-web);
}
.title-category a {
  color: var(--black-main-web);
  text-decoration: none;
}
.modal-layout {
  position: fixed;
  z-index: 9000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.hide{
  display: none !important;
}
.show{
  display: block !important;
}
.img-arrow{
  width: 8px;
  margin-right: 6px;
}
.errStatus{
  color:red;
  font-weight: 500;
  display: none;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}