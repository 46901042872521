$mobile-width: 600px;
@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
.footer-bg {
  background-color: var(--bg-black-main);
  color: #fff;
  margin-top: 60px;
  padding-top: 35px;
  .layout {
    display: flex;
    overflow: hidden;
    width: 100%;
    .col {
      .title {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
      }
      .content {
        margin-top: 20px;
        font-size: 14px;
        color: #fff;
        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .item {
        margin-bottom: 6px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          a {
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
      .contact {
        margin-top: 16px;
        display: flex;
        .icon {
          margin-left: 12px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .payment {
        margin-top: 16px;

        display: flex;
        .icon {
          margin-left: 6px;
          img {
            height: 35px;
          }
        }
      }
    }
  }
  .copy-right {
    margin-top: 35px;
    border-top: 1px solid #fff;
    width: 100%;
    padding: 16px 0;
    p {
      font-size: 14px;
      color: #fff;
      margin: 0;
    }
  }
  .footer-desktop {
    display: block;
    @include mobile {
      display: none;
    }
    .col-1 {
      width: 25%;
      padding-right: 30px;
      .content {
        .item {
          margin-bottom: 3px !important;
        }
      }
    }
    .col-2 {
      width: 18%;
    }
    .col-3 {
      width: 23%;
    }
    .col-4 {
      width: 34%;
      input {
        width: 100%;
        padding: 8px 16px;
        font-size: 14px;
        outline: none;
      }
    }
  }
  .footer-mobile {
    display: none;
    @include mobile {
      display: block;
    }
    .layout {
      .col {
        .title {
          font-size: 14px;
        }
        .item {
          font-size: var(--main-font-size);
          a {
            font-size: var(--main-font-size);
          }
        }
      }
      .col-1 {
        width: 33.33%;
        padding-right: 20px;
        .content {
          .item {
            margin-bottom: 4px !important;
          }
        }
      }
      .col-2 {
        width: 33.33%;
      }
      .col-3 {
        width: 33.33%;
      }
    }
    .col-4 {
      .title {
        font-size: 14px;
      }
      margin: 20px 0 0 0;
      width: 100%;
      input {
        width: 100%;
        padding: 8px 16px;
        font-size: 14px;
        outline: none;
      }
      .contact {
        margin-top: 16px;
        display: flex;
        .icon {
          margin-right: 12px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .payment {
        margin-top: 16px;

        display: flex;
        .icon {
          margin-right: 6px;
          img {
            height: 35px;
          }
        }
      }
    }
  }
}
